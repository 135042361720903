<script setup>
const isDesktop = ref(true);

onMounted(() => {
  checkDesktop();
  window.addEventListener("resize", checkDesktop);
});

const emits = defineEmits(["click"]);

const checkDesktop = () => {
  isDesktop.value = window.innerWidth >= 1064;
};
</script>

<template>
  <div class="logo">
    <div
      class="wrapper"
      @click="emits('click')"
    >
      <svg-logo
        :width="isDesktop ? 194 : 121"
        :height="isDesktop ? 32 : 20"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";

.wrapper {
  @apply flex items-center;
  height: rem(22px);
  @media (min-width: 1024px) {
    height: rem(29px);
  }
}
</style>
